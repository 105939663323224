.extended-modal {
  box-shadow: none;
  &.slide-left {}

  .modal-content {
    border-radius: 12px;
    .extended-layout {
      position: sticky;
      float: right;
      z-index: 100;
      margin-top: -2000px;
      top: 50%;
      display: flex;
      align-items: center;

      div.extended-content {
        border-radius: 12px;
        padding: 20px;
        position: absolute;
        left: 40px;
        background: white;
        overflow-y: scroll;
      }
    }
  }
}

.br-5 {
  border-radius: 5px !important;
}

.b-error {
  border: 1px solid red !important;
}