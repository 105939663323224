@import "./progress_load_indicator";
@import "./custom_alert_message";
@import "./simple_table_theme";

.nav-tabs.separator-tabs .nav-link {
  padding-right: 5px;
}

.heading-option-container {
  .has-float-label {
    width: 100%;
    margin: 0 !important;
  }
}
.uncontrolled-text-dropdown {
  button.btn-empty {
    padding: 0;
    font-weight: bold;
  }
}
.notification-container-withdropdown {
  .infinite-scroll-component {
    min-height: 400px;
  }
}

.rounded .sub-hidden .main-menu,
.rounded .menu-sub-hidden .main-menu {
  border-radius: 0;
}

.no-border-radius-card {
  .card {
    border-radius: 0;
  }
}

.notification-action {
  opacity: 1 !important;
  div {
    opacity: 1 !important;
  }
}

.align-arrow-drop .react-datepicker__triangle {
  left: 200px !important;
}
.void-invoice-list {
  background: rgba(255, 255, 255, 0.01);
}

.mark-as-paid-modal {
  border-top: 5px solid #145388;
}

.mark-as-retract-modal,
.confirm-error-modal {
  border-top: 5px solid #dc3545;
}

.mark-as-issue-modal {
  border-top: 5px solid #28a745;
}
.email-modal {
  border-top: 5px solid #e2863b;
}
.behalf-modal {
  border-top: 5px solid #2a93d5;
}
.invoice-analytics-container {
  margin: 20px 0;
  display: flex;
  height: 35px;
  .analytic-card {
    display: flex;
    align-items: flex-end;
    line-height: 1;
    font-size: 15px;
    margin-right: 20px;
    strong {
      font-size: 18px;
    }
    cursor: pointer;
    padding: 8px 25px 8px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
    &:hover,
    &.active {
      background: #e9e9e9;
    }
    span {
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 10%;
      &.issued {
        background: #3e884f;
      }
      &.paid {
        background: #c1c1c1;
      }
      &.unpaid {
        background: #131113;
      }
      margin-right: 10px;
    }
  }
}

.shimmer-skel-container {
  width: 100%;
  display: flex;
}

.shimmer {
  display: flex;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(40, 45, 58, 0.1);
    }
    > div {
      align-items: center;
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__block {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    animation-delay: 0.5s;
    background-color: #ededed;
    background-image: linear-gradient(
      90deg,
      #ededed 14.36%,
      #d7d6d6 56.29%,
      #ededed 100%
    );
    background-repeat: no-repeat;
    background-size: 244px 104px;
    position: relative;
    border-radius: 5px;
    width: 100%;
    margin: 3px 6px 3px 0px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: calc(0% - 300px) 0;
  }

  20% {
    background-position: calc(0% - 300px) 0;
  }

  80% {
    background-position: calc(100% + 300px) 0;
  }

  100% {
    background-position: calc(100% + 300px) 0;
  }
}

#invoice_payable_details {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#invoice_payable_details td,
#invoice_payable_details th {
  border: 1px solid #ddd;
  padding: 8px;
}

#invoice_payable_details tr:nth-child(even) {
  background-color: #f2f2f2;
}

#invoice_payable_details tr:hover {
  background-color: #efefef;
}

#invoice_payable_details th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #2a93d5;
  color: white;
}

#invoice_payable_details th.orange {
  background-color: #e2863b;
}

.stripe-form button {
  margin-top: 20px;
  display: block;
  background-color: #2a93d5;
  &:disabled {
    opacity: 0.5;
  }
  &.btn-cancel {
    background-color: white;
    border: 1px solid #ccc;
    color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  color: #fff;
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease;
}

.cursor-pointer {
  cursor: pointer;
}
.price-d-invoice {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  input {
    font-size: 14px;
    width: 100px;
    text-align: right;
  }
  .in-edit-action {
    span {
      padding: 5px 3px 0 3px;
      font-size: 17px;
    }
  }
}

#invoiceStatusModal {
  .modal-body {
    height: calc(100vh - 250px);
    overflow-x: scroll;
  }
}

.dropzone-clinic-logo {
  min-height: 150px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &.dropzone .dz-preview.dz-image-preview,
  .dropzone .dz-preview.dz-file-preview {
    width: unset;
    height: auto;
    border: none !important;
  }
  &.dropzone .dz-preview {
    margin: unset;
  }
  .dz-details {
    display: none !important;
  }
  &.dropzone .dz-preview .dz-image img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
  &.dropzone.dz-clickable .dz-message {
    top: 38px !important;
  }
}
