.border-gray {
  border: 1px solid #ccc;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-skyblue {
  background-color: #bae6fd;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none;
  transition: all 300ms linear;
}

.checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &.active {
    background-color: white;
    &::after {
      content: "✓";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.min-h-full {
  min-height: 100%;
  overflow: auto;
}

.max-h-full {
  max-height: 100%;
  overflow: auto;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
  overflow: auto;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.font-bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.bg-light-red {
  background-color: #ffcccc;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.items-baseline {
  align-items: baseline;
}

.processing-queue {}