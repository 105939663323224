.ol-overviewmap.ol-unselectable.ol-control {
  display: none !important;
}

.toolbox-container {
  margin-bottom: 5px;
  a {
    margin-right: 10px;
    i {
      font-size: 18px;
    }
  }
}

ul.color-selection-option {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: 50px;
  li {
    height: 40px;
    width: 40px;
    display: inline-block;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      border: "4px solid black;";
    }
  }
}

.active-slide {
  background-color: #f9d4d5 !important;
  border-right: 5px solid #e17b7f !important;
  color: #da4f5d !important;
  font-weight: bold;
}

.normal-slide {
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 15px;
  background-color: rgb(227, 227, 227);
  border-right: 5px solid #c3c3c3;
  cursor: pointer;
  &:hover {
    background-color: #f9e3e4 !important;
  }
}

main.dicom-viewer-enabled {
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 5px;
}

.dicom-sidebar-container {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  background-color: #eee;
  .info-chart-container {
    overflow-x: scroll;
    .annotation-container {
      background-color: white;
    }
  }
  .action-container {
    height: 130px;
    padding: 0 10px;
  }
}

.main-frame-container {
  height: calc(97% - 0px);
  overflow: hidden;
  margin-right: 12px;
  background-color: #000;
  &:after {
    content: "Loading viewer please wait...";
    color: white;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }
}

.dviwer-tool {
  background-color: white;
  display: inline-block;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  &.active,
  &:hover {
    background-color: #da4f5d !important;
    color: white;
  }
}
