.rack{
    .rack-item{
        transition: all .1s ease-in-out;
        width: 94%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        // margin-bottom: 1rem;
        border-radius: 8px;
        margin: 8px ;
        font-size: 28px;
        font-weight: bold;
        &::after{
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    .rack-item:hover{
        transform: scale(1.1);
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }

    .rack-item.active{
        transform: scale(1.1);
    }

    .rack-item.search-found{
        background-color: #15803d !important;
    }
    .rack-item.filled{
        background-color: #be123c;
    }
    .rack-item.empty{
        background-color: darkgray;
    }
    .rack-item.selected{
        transform: scale(1.1);
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
        background-color: #912bd5 !important;
    }
}

    .rack-search-header{
        display: flex;
        justify-content: space-around;
        align-items: center;
        // background-color: #3bb347;
        // border-radius: 8px;
        // color: white;
        margin-bottom: 10px;
        height: 35px;
        transition: all .1s ease-in-out;
        // border: black, 1px solid;
          border-bottom: 1px solid #434242;

        // cursor: pointer;
    }

    .rack-search-item{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #3bb347;
        border-radius: 8px;
        color: white;
        margin-bottom: 10px;
        height: 35px;
        transition: all .1s ease-in-out;
        cursor: pointer;

    }
    .rack-search-item:hover{
        //   transform: scale(1.1);
         box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }


    .rack{
         .app-menu{
            width: 295px;
        }
        .app-row {
        padding-right: 295px;
        }
    }
    
#rack-modal{
    .modal-content{
        border-radius: 12px;
    }
}

#rack-sample-modal{
    .modal-content{
        border-radius: 12px;
    }
    .modal-title{
        width: 100%
    }
}

    
